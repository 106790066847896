<template>
	<div class="menu-navi">
		<span v-for="(menu, index) in $store.getters.SELECTED_MENU" v-bind:key="menu">
			<span v-if="index>0">></span>
			<router-link :to="menu.MENU_URL" class="menu_item">{{$t(menu.MENU_NAME)}}</router-link>
		</span>
	</div>
</template>
<style>
	.menu-navi {
		background-color: #f7f7f7;
	}
</style>
