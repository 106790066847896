<template>
	<ul class="nav nav-pills flex-column mt-3">
		<button v-for="menu in $store.getters.MENU_LIST_PARENT(topMenuId)" v-bind:key="menu" @click="leftMenuClick(menu)" :class="getActiveMenu(menu)"
			class="menu_item nav-link gap-3 rounded-0 rounded-start rounded-pill d-flex align-items-center">
			<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-person-circle text-center" viewBox="0 0 16 16">
				<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
				<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
			</svg>
			{{$t(menu.MENU_NAME)}}
		</button>
	</ul>
</template>
<script>
	import { computed } from "vue";
	import { router } from "@/routes/index.js";
	import store from "@/store/index.js";

	export default 
	{
		setup() 
        {
            const topMenuId = computed(() => {
				// 상단메뉴 클릭으로 선택된 메뉴
				let result = store.getters.TOP_MENU_ID;
				if (!result) {
					// 페이지 Refresh할때 URL에서 현재메뉴 추출 후 1번째 메뉴레벨 가져오기
					result = store.getters.SELECTED_MENU_LEVEL(1).MENU_ID; 
				}
				return result;
			});
            return { topMenuId }
        },
		data() {
			return {
				leftMenuId: "" // 좌측메뉴 선택
			}
		},
		methods: 
        {
			// 좌측 메뉴 클릭
			leftMenuClick(menu)
			{
				this.leftMenuId = menu.MENU_ID;
				router.push({"path": menu.MENU_URL});
				store.commit("setSelectedMenu", this.leftMenuId);
			},
			// 메뉴 활성화 여부 설정
			getActiveMenu(menu)
			{
				let activeMenuId = this.leftMenuId;
				if (!activeMenuId) {
					activeMenuId = this.$store.getters.SELECTED_MENU_ID(2);
				}
				return menu.MENU_ID == activeMenuId ? "active" : "";
			}
		},
	}
</script>
<style>
	.menu_item {
		margin-top: 2px;
		margin-bottom: 2px;
		cursor: pointer;
	}
</style>